<template>
	<v-container
		class="pa-0 ma-0 bg-eee mypage"
	>
	
		<v-card
			class="pa-2 ma-0 text-left"
			elevation="1"
			tile
		>
			<v-avatar 
				
			>
				<img
					v-if="user.profile_img"
					class="ma-0 text-center"
					:src="user.profile_img" 
				>
				
				<v-icon
					large
				>mdi-account-circle</v-icon>
				
			</v-avatar>
			
			<span v-if="user.businessName" class="mt-3 font-weight-bold text-subtitle-2">{{ user.businessName}}</span>
			<span v-if="!user.businessName" class="mt-3 text-subtitle-2">{{ user.businessName | userBusinessName}}</span>
			
			<v-btn
				v-if="!user.profile_img"
				class="mt-3 float-right"
				small
				icon
				:to="'/User/Setting'"
			>
				<v-icon>mdi-cog-outline</v-icon>
			</v-btn>
		</v-card>
	
		<v-simple-table
			class="pa-3 pb-0 mt-3"
			>
			<colgroup>
				<col width="100px" />
				<col width="auto" />
			</colgroup>
			<caption
				class="caption"
			>
				<span>기본정보</span>
			</caption>
			<tbody>
				<tr>
					<td>아이디</td>
					<td>{{ user.userId }}</td>
				</tr>
				<tr>
					<td>비밀번호</td>
					<td>
						비밀번호 변경
						<v-btn
							class="position-right"
							:to="'/User/Password'"
							icon
							large
						>
							<v-icon>mdi-chevron-right</v-icon>
						</v-btn>
					</td>
				</tr>
				<tr>
					<td>PIN 로그인</td>
					<td>
						{{ user.is_pin == '사용' ? 'PIN 설정' : 'PIN 재설정'}}
						<v-btn
							class="position-right"
							:to="'/User/Pin'"
							
							icon
							large
						>
							<v-icon>mdi-chevron-right</v-icon>
						</v-btn>
					</td>
				</tr>
				<tr
					v-if="user.role == 'ROLE_MERCHANT'"
				>
					<td>정산 주기</td>
					<td>{{ user.settlementCycle}}</td>
				</tr>
				<tr
					v-if="user.role == 'ROLE_MERCHANT'"
				>
					<td>정산 수수료</td>
					<td>{{ user.commissionRate}}</td>
				</tr>
			</tbody>
		</v-simple-table>
	
		<v-simple-table
			class="pa-3 pb-0 mt-3"
			>
			<colgroup>
				<col width="100px" />
				<col width="auto" />
			</colgroup>
			<caption
				class="caption"
			>
				<span>사업자 정보</span>
			</caption>
			<tbody>
				<tr>
					<td>상호명</td>
					<td>{{ item.businessName }}</td>
				</tr>
				<tr>
					<td>사업자 번호</td>
					<td>{{ item.businessNumber}}</td>
				</tr>
				<tr>
					<td>대표자</td>
					<td>{{ item.ceoName}}</td>
				</tr>
				<tr>
					<td>주소</td>
					<td>{{ item.address }}</td>
				</tr>
				<tr>
					<td>연락처</td>
					<td>{{ item.businessPhone }}</td>
				</tr>
				<tr>
					<td>대리점</td>
					<td>{{ item.agentName }}</td>
				</tr>
			</tbody>
		</v-simple-table>
		
	
		<v-simple-table
			v-if="false"
			class="pa-3 pb-0 mt-3"
			>
			<colgroup>
				<col width="auto" />
				<col width="100px" />
			</colgroup>
			<caption
				class="caption"
			>
				<span>알림 설정</span>
			</caption>
			<tbody>
				<tr>
					<td>공지사항/업데이트</td>
					<td class="text-right"> 
						ON
					</td>
				</tr>
			</tbody>
		</v-simple-table>
		
		
		<v-simple-table
			v-if="false"
			class="pa-3 pb-0 mt-3"
			>
			<colgroup>
				<col width="auto" />
				<col width="100px" />
			</colgroup>
			<caption
				class="caption"
			>
				<span>고객센터 / 도움말</span>
			</caption>
			<tbody>
				<tr>
					<td>문의 및 개선요청</td>
					<td class="text-right">
						<v-btn
							icon
							:to="'/Help/making'"
						>
							<v-icon>mdi-chevron-right</v-icon>
						</v-btn>
					</td>
				</tr>
			</tbody>
		</v-simple-table>
		
		
		<v-simple-table
			class="pa-3 pb-0 mt-3"
			>
			<colgroup>
				<col width="auto" />
				<col width="50px" />
			</colgroup>
			<caption
				class="caption"
			>
				<span>약관 및 정책</span>
			</caption>
			<tbody>
				<tr
					v-for="(agree, index) in agrees"
					:key="index"
				>
					<td>{{ agree.name }}</td>
					<td class="text-right">
						<v-btn
							icon
							@click="modal(index)"
							class="pa-0"
						>
							<v-icon>mdi-chevron-right</v-icon>
						</v-btn>
					</td>
				</tr>
			</tbody>
		</v-simple-table>
		
		<div
			class="pa-5 text-center"
		>
			<div
				class="bg-white"
			>
				<v-btn
					block
					color="success2"
					class="bg-white"
					@click="logout"
					outlined
				>
					로그아웃
				</v-btn>
			</div>
		</div>
		
	</v-container>
</template>

<script>
	import { TERMS } from '@/values/ValueTerms.js'
	
	export default{
		name: 'Mypage'
		,created: function(){
			this.$emit('setProgram', this.program, this.options)
			this.getData()
		}
		,props: ['user', 'callBack']
		,data: function(){
			return {
				program: {
					title: 'MyPage'
					,not_navigation: true
					,prev: {
						to: '/Home'
					}
				}
				,options: {
				}
				,item:{
					id: 'user id'
					,name: '간편결제 사업자'
					,company_name: '립페이 간편결제'
					,company_num: '123-45-6789'
					,company_ceo: '립페이'
					,company_addr: '서울시 강남구 선릉로 121길 7 2층'
					,company_tell: '1588-5555'
					,company_dae: '와이엘솔루션'
					,company_cycle: 'D+5'
					,company_fee: '4.5%'
					,profile_img: ''
					,is_pin: '사용'
				}
				,agrees: [
					{
						name: '이용약관'
						,contents: TERMS.AGREE
					}
					,{
						name: '전자금융거래 이용약관'
						,contents: TERMS.TRANSACTION
					}
					,{
						name: '개인정보 수집/이용안내'
						,contents: TERMS.PERSONAL
					}
				]
			}
		}
		,filters: {
			userBusinessName: function(value){
				if(!value) return '판매자명이 등록되지 않았습니다'
				return value + '님'
			}
		}
		,methods:{
			logout: function(){
				this.$emit('logout')
			}
			,getData: function(){
				this.$emit('axios', {
					request_type: 'get'
					,request_url: '/users/me'
					,authorize: true
					,callBack: 'getDataResult'
				})
			}
			,getDataResult: function(call){
        console.log('call : ' || call)
				if(call.result){
					this.item = call.items.content.merchant
				}
			}
			,modal: function(index){
				let item = this.agrees[index]
				this.$emit('modal', {
					title: item.name
					,contents: item.contents
					,accept: '확인'
				})
			}
		}
		,watch:{
			callBack: {
				deep: true
				,handler: function(call){
					if(call.name == 'getDataResult'){
						this.getDataResult(call)
					}
				}
			}
		}
	}
</script>

<style>

	tr td { 
		padding: 5px 2px !important; 
		height: auto; 
		border-bottom: 2px solid #ddd; 
		font-size: 0.8em !important;
		position: relative;
	}
	
	tr:last-child td {
		border: none;
	}
	
	td:nth-child(even) { text-align: left; }
	td:nth-child(odd) {font-weight: bold; text-align: left;}
	
	.mypage {}
	.mypage .caption {border-bottom: 1px solid #ddd; text-align: left; padding: 5px 0 0; font-weight: bold !important; font-size: 1em
	!important;}
	.mypage .caption span {border-bottom: 1px solid #424242; display: inline-block; padding: 0 15px 5px 0;}
	.mypage .position-right {position: absolute; right: 0; top: 3px;}
</style>







